import { Component, Directive, AfterContentInit } from "@angular/core"

/*#################################

restricted-layout-toolnavbar
restricted-layout-titulo
restricted-layout-subtitulo
restricted-layout-contenido
restricted-layout-tabs
restricted-layout-topside

#################################*/

@Component({
    templateUrl: "restricted_layout.component.html",
    selector: "restricted-layout",
    styleUrls: ["restricted_layout.component.scss"]
})
export class RestrictedLayoutComponent {}
